@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter.ttf') format('truetype');
  
  /* Add other font weights and styles similarly */
}
body {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(to right, rgb(177, 56, 168), rgb(250, 116, 5));
}
/* App.css */
.dashboard-container {
    width: 90%;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
    color: #485161;
  }
  
  .dashboard-file-input {
    padding: 10px;
    border: none;
    background-color: #1c1c1c;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chart-container {
    width: 100%; /* Chart takes 100% of the container width */
    margin-top: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
    
    background-color: #ffffff;
  }
  .stat-box {
    width: 20%;
    padding: 10px;
    margin: 10px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
    background-color: #f8f8f8; /* Background color */
  }
  
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #485161;
    margin-bottom: 10px;
  }
  
  .value {
    font-size: 62px;
    color: #485161;
    font-weight: 500;
  }
  .value span{
    font-size: 25px;
  }
  
  .description {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  .dashboard {
    padding-top: 20px;
    /* display: ruby-text; /* Display boxes in the same row */
  }
  .dashboard-stats {
    padding-top: 200px;
    display: ruby-text;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  li {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 10px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1 0 200px; /* Adjust as needed */
    max-width: calc(33.333% - 20px); /* Adjust as needed */
    /* You can set specific width for each card using flex-basis */
  }
  .follow-scroll {
    position: relative;
    /* Additional styling */
    background-color: #f0f0f0; /* Background color */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Box shadow */
    z-index: 1000; /* Adjust as needed */
    height: auto;
}
.follow-scroll.fixed {
    position: fixed;
    top: 0;
    left: 15%;
    padding: 10px;
    width: 70%; /* Adjust as needed */
}
.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-tooltip .label {
  margin: 0;
}
.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.progress-bar {
  height: 100%;
  background-color: #007bff; /* Change color as desired */
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}
.button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
}

.custom-button {
  border: none;
  width: 150px;
  height: 45px;
  color: white;
  background: black;
  padding: 15px;
  font-size: 15px;
  margin-right: 30px; /* Adjust as needed */
  border-radius: 15px;
  cursor: pointer;
}

.custom-button:last-child {
  margin-right: 0; /* Remove margin for the last button */
}

.checkbox-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-button {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.checkbox-button.checked {
  background-color: black;
  color: white;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkbox-button .checkbox-inner {
  width: 16px; /* Consistent checkbox size */
  height: 16px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
  line-height: 14px;
  margin-right: 10px; /* Space between checkbox and text */
}

/* Styles for the error modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 99999;
}

.modal-content button {
  margin-top: 10px;
}

/* Preloader.css */
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
